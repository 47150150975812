import React from "react";
import wrapMetaTitle from "./wrapMetaTitle";
import Languages from "../utils/languages";
import Links from "./links";

const DefaultHead = ({ children, pageContext }) => {
    const {lang='fr', slug, title, description=''} = pageContext
    let canonical = null
    const links = Links()
    const urlLang = (slug,l=lang)=>links[slug][l].uri
    if (!!slug) {
        canonical = Languages.map(l =>
            <link key={l}
                  rel={l === lang ? "canonical" : "alternate"}
                  href={urlLang(slug, l)}
                  hrefLang={l}
            />)
    }
    return (<>
        <title id="title">{wrapMetaTitle(title,lang)}</title>
        {title && <meta name={"og:title"} content={title} />}
        {description && <meta name={"description"} content={description} />}
        {description && <meta name={"og:description"} content={description} />}
        {canonical}
        {children}
    </>)
}


export default DefaultHead;